export class UserInboxUrlsModel {
  id
  userId
  message
  phone
  msisdn
  createdAt
  updatedAt

  constructor () {
    this.id = null
    this.userId = null
    this.message = ''
    this.phone = ''
    this.msisdn = ''
    this.createdAt = ''
    this.updatedAt = ''
  }

  loadData (data) {
    this.id = data.id
    this.userId = data.user_id
    this.message = data.message
    this.phone = data.phone
    this.msisdn = data.msisdn
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }
}

import UserInboxUrlsService from '@/services/whatsapp/user-inbox-urls.service.js'
import { UserInboxUrlsModel } from '@/models/whatsapp/UserInboxUrls'
import EventBus from '@/util/EventBus'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'

export default {
  name: 'UserInboxUrls',
  components: { TextFormatting },
  props: {
    selectedUserMsisdnConfig: {
      type: String, required: true,
    },
  },
  data () {
    return {
      showModalUrlsDialog: false,
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      userInboxUrlsModel: new UserInboxUrlsModel(),
      debouncedInput: '',
      timeout: null,
      confirmTriggeredMessageDialog: false,
      user: this.$store.getters['auth/getUser'],
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('URL'),
          value: 'phone',
        },
        {
          text: this.$t('Mensaje'),
          value: 'message',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataTable()
      },
      deep: true,
    },
    selectedUserMsisdnConfig: {
      handler: function (selectedUserMsisdnConfig) {
        if (selectedUserMsisdnConfig !== '') {
          this.getDataTable()
        }
      },
      deep: true,
    },
  },
  methods: {
    async copyLinkToClipboard (item) {
      try {
        await navigator.clipboard.writeText(this.waMe(item.phone, item.message))
        EventBus.$emit('showAlert', 'success', this.$t('¡Enlace copiado al portapapeles!'))
      } catch (err) {}
    },
    waMe (phone, message) {
      return `https://wa.me/${phone}?text=${encodeURIComponent(message)}`
    },
    setTextFormatContext () {
      setTimeout(() => {
        this.$refs.userInboxUrlsModelTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
      })
    },
    deleteTriggeredMessage () {
      UserInboxUrlsService.delete(this.userInboxUrlsModel).then(() => {
        this.userInboxUrlsModel = new UserInboxUrlsModel()
        this.confirmTriggeredMessageDialog = false
        this.getDataTable()
        EventBus.$emit('showAlert', 'success', this.$t('Se ha eliminado la URL'))
      })
    },
    preRemoveTriggeredMessage (item) {
      this.userInboxUrlsModel = item
      this.confirmTriggeredMessageDialog = true
    },
    newUrl () {
      this.userInboxUrlsModel = new UserInboxUrlsModel()
      this.showModalUrlsDialog = true
      this.setTextFormatContext()
    },
    storeUrl () {
      this.userInboxUrlsModel.msisdn = this.selectedUserMsisdnConfig
      this.userInboxUrlsModel.phone = this.selectedUserMsisdnConfig
      if (this.userInboxUrlsModel.id === null) {
        this.createUrl()
      } else {
        this.updateUrl()
      }
    },
    createUrl () {
      UserInboxUrlsService.create(this.userInboxUrlsModel).then(
        () => {
          this.userInboxUrlsModel = new UserInboxUrlsModel()
          this.showModalUrlsDialog = false
          this.getDataTable()
          EventBus.$emit('showAlert', 'success', this.$t('Se ha creado la url correctamente.'))
        },
        () => {
          EventBus.$emit('showAlert', 'warning', this.$t('Ocurrió un problema y no se pudo guardar la url'))
        },
      )
    },
    updateUrl () {
      UserInboxUrlsService.update(this.userInboxUrlsModel).then(
        () => {
          this.userInboxUrlsModel = new UserInboxUrlsModel()
          this.showModalUrlsDialog = false
          this.getDataTable()
          EventBus.$emit('showAlert', 'success', this.$t('Se ha actualizado la url correctamente.'))
        },
        () => {
          EventBus.$emit('showAlert', 'warning', this.$t('Ocurrió un problema y no se pudo guardar la url'))
        },
      )
    },
    prepareEdit (item) {
      this.userInboxUrlsModel = item
      this.showModalUrlsDialog = true
      this.setTextFormatContext()
    },
    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        msisdn: this.selectedUserMsisdnConfig,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataTable () {
      const data = this.getParams()
      this.disabledPagination = true
      UserInboxUrlsService.getDataTable(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
  },
  mounted () {
    this.getDataTable()
  },
}
